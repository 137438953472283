import React from "react"
import { Col, Form, Row } from "react-bootstrap"

type baseProps = {
  label: string
  value: string
  type?: string
}

type forNumber = {
  type: "number"
  min?: string
}

type ownProps = baseProps | (baseProps & forNumber)

const ReadOnlyTextField: React.FC<ownProps> = ({
  label,
  value,
  type
}) => {
  return (
    <Form.Group as={Row} controlId="formAppName">
      <Form.Label column sm={2}>
        {label}
      </Form.Label>
      <Col sm={5}>
        <Form.Control
          type= {type ?? "text"}
          value={value}
          readOnly
        />
      </Col>
    </Form.Group>
  )
}

export default ReadOnlyTextField