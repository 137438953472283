import React, { useEffect } from "react"
import { useIntl } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import { RouteComponentProps } from "react-router"
import {
  Button,
  Card,
  Col,
  Form,
  Row,
} from "react-bootstrap"
import { Formik } from "formik"
import * as Yup from "yup"
import * as ja from "yup-locale-ja"

import { fetchAssetsAction } from "../state/ducks/assets/actions"
import { addCertAction } from "../state/ducks/certs/actions"
import { fetchAppInfoOps } from "../state/ducks/apps/operations"
import { appInfoSelector } from "../state/ducks/apps/selectors"
import { isProcessingSelector } from "../state/ducks/notifications/selectors"
import { State } from "../state/store"
import {
  fetchSealToUsersAction,
} from "../state/ducks/seal_to_users/actions"

import PrevLink from "../components/PrevLink"
import FormTextField from "../components/FormTextField"
import Spinner from "../components/Spinner"

import ReadOnlyTextField from "../components/ReadOnlyTextField"
import * as Util from "../util"
import FormSelect from "../components/FormSelect"
import { checkDecimalLength } from "../util"

Yup.setLocale(ja.suggestive)

/**
 * 証明書発行ページ
 */
const AddCertPage: React.FC<RouteComponentProps<{
  appCode: string
  assetCode: string
//  sealPrefix: string
}>> = ({ match, history }) => {
  const { appCode, assetCode } = match.params
  const dispatch = useDispatch()
  const intl = useIntl()

  const isProcessing = useSelector(isProcessingSelector)

  const appInfo = useSelector(appInfoSelector)
  const assetState = useSelector((state: State) => state.assetsReducer)
  const asset = assetState.data.assets.find((asset: any) => asset.assetCode === assetCode)

  const vendorState = useSelector((state: State) => state.vendorReducer)
  const vendorId = (vendorState.isLoaded && vendorState.vendor.vendorId) as string
  const isDnpKyoto2 = Util.isDnpKyoto2(vendorId, appCode)

  useEffect(() => {
    dispatch(fetchAssetsAction.started({ appCode, params: {} }))
    dispatch(fetchAppInfoOps(appCode))
  }, [dispatch, appCode])

  let schema
  schema = Yup.object({
    assetSeq: Yup.number().min(1),
    userId: Yup.string().required().max(100),
    currency: Yup.string().required().oneOf(
      ["JPY", "USD", "CNY", "KRW", "EUR"],
      intl.formatMessage({ id: "yup-cert-currency" })
    ),
    sealId: Yup.string().length(5),
  })
  if (appInfo.isLoaded && appInfo.data.priceFlag) {
    schema = schema.concat(
      Yup.object({
        price: Yup.number().required().min(0.00).max(10000000.00)
        .test("price", intl.formatMessage({ id: "yup-cert-price" }), function(value) {
          const length = checkDecimalLength(value)
          if (length > 2) {
            return false
          }
          return true
        }),
      }),
    )
  }
  if (isDnpKyoto2) {
    schema = schema.concat(
      Yup.object({
        info1: Yup.string().required().max(200),
        info2: Yup.string().required().max(200),
        info3: Yup.string().max(200),
        info4: Yup.string().max(200),
      }),
    )
  }
  //console.log("appInfo", appInfo)
  if (appInfo.isLoaded && appInfo.data.seqFlag) {
    schema = schema.concat(
      Yup.object({
        assetSeq: Yup.number().required().min(1),
      }),
    )
  }

  const submit = (values: any) => {
    //console.log("values", values)

    let sealPrefix = ""
    if (appInfo.isLoaded && appInfo.data.sealPrefix) {
      sealPrefix = appInfo.data.sealPrefix
    }
    //console.log("sealPrefix=" + sealPrefix)
    //console.log("sealId=" + sealId)
    //const userId = fetchSealToUsersAction()
    dispatch(fetchSealToUsersAction.started({ appCode, params: {} }))
    const data = {
      vendorId,
      appCode,
      assetCode,
      assetSeq: values.assetSeq,
      userId: values.userId,
      sealId: sealPrefix + values.sealId,
      price: values.price,
      currency: values.currency,
      info1: values.info1,
      info2: values.info2,
      info3: values.info3,
      info4: values.info4,
      seqFlag: appInfo.isLoaded && appInfo.data.seqFlag,
      priceFlag: appInfo.isLoaded && appInfo.data.priceFlag,
      sealFlag: appInfo.isLoaded && appInfo.data.sealFlag,
      sealToUserFlag: appInfo.isLoaded && appInfo.data.sealToUserFlag
    }
    //console.log("data", data)

    dispatch(
      addCertAction.started(data)
    )
  }

  if (!(assetState.isLoaded && appInfo.isLoaded)) {
    return <Spinner />
  }

  const currencyOptions = (currencies: string[]) => {
    return (
      <React.Fragment>
        {currencies.map(currency => (
          <option key={currency} value={currency}>
            {currency}
          </option>
        ))}
      </React.Fragment>
    )
  }
  
  return (
    <>
      <PrevLink to={`/apps/${appCode}/assets/${assetCode}`} label={asset ? asset.assetName : ""} />
      <Row className="mb-4">
        <Col>
          <h2 className="m-0">
            {intl.formatMessage({ id: "add-cert.title" })}
          </h2>
        </Col>
      </Row>
      <Card>
        <Card.Body>
          <Formik
              validationSchema={schema}
              onSubmit={(values, { setSubmitting }) => {
                submit(values)
              }}
              initialValues={{
                assetSeq: "",
                userId: "",
                sealId: "",
                price: "",
                currency: "JPY",
                info1: "",
                info2: "",
                info3: "",
                info4: "",
              }}
            >
            {({
              handleSubmit,
              handleChange,
              values,
              isValid,
              errors
            }: any) => (
              <Form noValidate onSubmit={handleSubmit}>
                <ReadOnlyTextField
                  label={intl.formatMessage({ id: "app-name" })}
                  value={appCode}
                />
                <ReadOnlyTextField
                  label={intl.formatMessage({ id: "asset-name" })}
                  value={asset ? asset.assetName : ""}
                />
                {appInfo.data.seqFlag && (
                  <FormTextField
                    controlId="asset-sequence"
                    label={intl.formatMessage({ id: "asset-sequence" })}
                    type="number"
                    name="assetSeq"
                  />
                )}
                {!appInfo.data.sealToUserFlag && (
                  <FormTextField
                    controlId="user-id"
                    label={intl.formatMessage({ id: "user-id" })}
                    name="userId"
                    required
                  />
                )}
                {appInfo.data.sealFlag && (
                  <FormTextField
                    controlId="seal-id"
                    label={intl.formatMessage({ id: "seal-id" })}
                    type="number"
                    name="sealId"
                    inputGroupPrepend={appInfo.data.sealPrefix}
                    required
                  />
                )}
                {appInfo.data.priceFlag && (
                  <>
                    <FormTextField
                      controlId="price"
                      label={intl.formatMessage({ id: "price" })}
                      type="number"
                      name="price"
                      required
                    />

                    <FormSelect
                      controlId="currency"
                      label={intl.formatMessage({ id: "currency" })}
                      name="currency"
                      children={currencyOptions(["JPY", "USD", "CNY", "KRW", "EUR"])}
                      required
                    />
                  </>
                )}

                {isDnpKyoto2 && (
                  <>
                    <FormTextField
                      controlId="info1"
                      label={intl.formatMessage({ id: "dnp_cert_info1" })}
                      name="info1"
                      required
                    />
                    <FormTextField
                      controlId="info2"
                      label={intl.formatMessage({ id: "dnp_cert_info2" })}
                      name="info2"
                      required
                    />
                    <FormTextField
                      controlId="info3"
                      label={intl.formatMessage({ id: "dnp_cert_info3" })}
                      name="info3"
                    />
                    <FormTextField
                      controlId="info4"
                      label={intl.formatMessage({ id: "dnp_cert_info4" })}
                      name="info4"
                    />
                  </>
                )}

                <Row>
                  <Col sm={{ span: 2, offset: 2 }}>
                    {isProcessing ? (
                      <Spinner />
                    ) : (
                        <Button type="submit" variant="success">
                          {intl.formatMessage({ id: "issue-cert-button" })}
                        </Button>
                      )}
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>

      <Button
        className="my-4"
        variant="outline-secondary"
        onClick={() => history.goBack()}>
        {intl.formatMessage({ id: "back-button" })}
      </Button>
    </>
  )
}

export default AddCertPage
