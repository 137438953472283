import {
  axiosWithToken,
  convertQueryParams,
  convertFilterParams,
  getMonthRange,
  getLastWeekRange,
  getLastSixMonthRange
} from "./../../../util"
import { Cert, CertsData, HistoriesData, HistoryFilter, CountTypes } from "./types"
import { AxiosResponse } from "axios"
import { QueryParams } from "../../../types"
import { FetchCertsHistoryParams } from "./actions"
import * as Util from "../../../util"

export const fetchCertUsecase = async (
  token: string,
  id: string
): Promise<Cert> => {
  // TODO: error handling
  const res = await axiosWithToken(token).get(`/certs/${id}?appCode=*`)
  return res.data
}

export const fetchCertsUsecase = async (
  token: string,
  appCode: string,
  assetCode: string,
  params: QueryParams
): Promise<CertsData> => {
  // TODO: error handling
  const queryParams = convertQueryParams(params)
  const url =
    assetCode === "*"
      ? `/certs?appCode=${appCode}&${queryParams}`
      : `/certs?appCode=${appCode}&assetCode=${assetCode}&${queryParams}`
  const res: AxiosResponse<CertsData> | void = await axiosWithToken(token).get(
    url
  )

  if (!res) {
    return {
      certs: [],
      count: 0
    }
  }

  return res.data
}

export const fetchCertsHistoryUsecase = async (
  token: string,
  params: FetchCertsHistoryParams
): Promise<HistoriesData> => {
  // TODO: error handling
  const queryParams = convertQueryParams(params)
  const filterParams = convertFilterParams(params)

  const url = `/certs/history?${queryParams}&${filterParams}`
  const res = await axiosWithToken(token).get(url)
  return res.data
}

type Data = {
  assetCode: string
  assetName: string
  assetSeq: string
  userId: string
  sealId: string
  vendorName: string
  creator: string
  copyright: string
  price: string
  currency: string
  info1: string
  info2: string
  info3: string
  info4: string
  seqFlag: boolean
  priceFlag: boolean
  sealFlag: boolean
}

/**
 * 証明書追加
 * @param token トークン
 * @param vendorId ベンダーID
 * @param appCode アプリコード
 * @param data データ
 */
export const addCertUsecase = async (
  token: string,
  vendorId: string,
  appCode: string,
  data: Data
) => {
  const isDnpKyoto2 = Util.isDnpKyoto2(vendorId, appCode)

  const d = Object.assign(
    {},
    {
      assetCode: data.assetCode,
      assetName: data.assetName,
      userId: data.userId,
      vendorName: data.vendorName,
      creator: data.creator,
      copyright: data.copyright
    },
    data.seqFlag ? { assetSeq: data.assetSeq } : {},
    data.priceFlag ? { price: data.price, currency: data.currency } : {},
    data.sealFlag ? { sealId: data.sealId } : {},
    isDnpKyoto2 ? { info1: data.info1, info2: data.info2, info3: data.info3, info4: data.info4 } : {},
  )
  const res = await axiosWithToken(token).post(`/certs?appCode=${appCode}`, d)

  return res.data
}

export const transferCertUsecase = async (
  token: string,
  appCode: string,
  certId: string,
  data: {
    oldOwnerId: string
    newOwnerId: string
    price: string
    currency: string
    priceFlag: boolean
  }
) => {
  const d = Object.assign(
    {},
    {
      oldOwnerId: data.oldOwnerId,
      newOwnerId: data.newOwnerId
    },
    data.priceFlag ? { price: data.price, currency: data.currency } : {}
  )

  await axiosWithToken(token).put(
    `/certs/${certId}/transfer?appCode=${appCode}`,
    d
  )
}

export const updateSealUsecase = async (
  token: string,
  appCode: string,
  certId: string,
  sealId: string
) => {
  await axiosWithToken(token).put(
    `/certs/${certId}?appCode=${appCode}`,
    {sealId}
  )
}

export const downloadCSVUsecase = async (token: string, params: HistoryFilter) => {
  const filterParams = convertFilterParams(params)

  const res: AxiosResponse<Blob> | void = await axiosWithToken(
    token,
    "blob"
  ).get(`/certs/history/download?${filterParams}`)

  return res ? res.data : ""
}

const fetchCertsCount = async (token: string, params: FetchCertsHistoryParams) => {
  const filterParams = convertFilterParams(params)
  const res = await axiosWithToken(token).get(
    `/certs/history/count?${filterParams}`
  )
  return res ? res.data : {}
}

export const fetchHistoryCountUsecase = async (
  token: string,
  appCode: string
): Promise<CountTypes> => {
  const date = new Date()
  const allHistoriesCounts = await fetchCertsCount(token, {
    appCode,
    from: null,
    to: null
  })
  const thisMonthCount = await fetchCertsCount(token, {
    appCode,
    ...getMonthRange(date)
  })
  const lastWeekCount = await fetchCertsCount(token, {
    appCode,
    group: "date",
    ...getLastWeekRange(date)
  })
  const lastSixMonthCount = await fetchCertsCount(token, {
    appCode,
    group: "month",
    ...getLastSixMonthRange(date)
  })

  return {
    allHistoriesCounts,
    thisMonthCount,
    lastWeekCount,
    lastSixMonthCount
  }
}
